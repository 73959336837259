// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k4ybyEFT{margin:0;padding:0 0 40px}@media(min-width:947.98px){.k4ybyEFT{padding:0 0 190px}}@media(min-width:1023.98px){.k4ybyEFT{--p:12.5%;padding-left:12.5%;padding-left:var(--p);padding-right:12.5%;padding-right:var(--p)}}.jABk6XOW{margin:0 0 20px;width:100%}@media(min-width:947.98px){.jABk6XOW{flex:0 0 60%;margin:0}}.P1LVz260{padding:14px 0}.QM4W75FA{font-size:24px;line-height:32px;margin:0;padding:0 0 14px}@media(min-width:947.98px){.QM4W75FA{font-size:26px;line-height:1.33;padding:0 0 24px}}.Gf04Ze7Z{color:var(--gradient-to);cursor:pointer;font-size:18px}.wJN24fSL{font-size:16px;line-height:24px;margin:0;padding:0 14px;text-align:left}@media(min-width:947.98px){.wJN24fSL{font-size:18px;line-height:26px;padding:0 24px}}.wJN24fSL p{margin:0;padding:0 0 14px}.VSKzb1DG>div{background:none;padding-left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "k4ybyEFT",
	"translation": "jABk6XOW",
	"session": "P1LVz260",
	"sessionTitle": "QM4W75FA",
	"sessionButton": "Gf04Ze7Z",
	"sessionText": "wJN24fSL",
	"bar": "VSKzb1DG"
};
module.exports = ___CSS_LOADER_EXPORT___;
